import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function About() {
  return (
    <div id="about" className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-7 d-flex">
          <div>
            <h2>About Me</h2>
            <p>I am a results-driven Web Developer and SEO specialist with a strong foundation in modern web technologies. Based in Westchester, NY, I have a proven track record of delivering high-quality web applications and driving measurable growth through innovative SEO strategies. My experience includes launching several successful projects that leverage React, Bootstrap, and advanced database management systems like MongoDB and SQL.</p>
            <div>
              <img src="/Assets/HeadShot.jpg" className="img-fluid rounded-circle mb-3 floating-image" alt="Headshot" />
            </div>
            <p>In addition to my technical skills, I have a deep understanding of UI/UX principles, enabling me to create user-friendly, visually appealing web experiences. My expertise extends to deploying applications on platforms like Firebase, and I am particularly adept at using tools like LucidChart, Trello, and Figma for efficient project management and design.</p>
            <p>One of my most impactful projects was the development of a pet care web app that connects potential pet owners with adoptable animals based on location and preferences. This project not only showcased my full-stack capabilities but also my commitment to creating solutions that make a difference.</p>
            <p>I am excited about the opportunity to contribute to a forward-thinking company, particularly one that aligns with my passions in animal welfare, music and film production, culinary arts, or social betterment. My long-term goal is to grow into a senior developer role where I can lead and mentor a team, driving innovation and excellence.</p>
            <p>I'm eager to connect with professionals who share my passion for technology and innovation. Let's discuss how I can bring value to your team and projects.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
